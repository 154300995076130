.post-data{
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
}
.post-share{
    display: grid;
    grid-template-columns: 2.5rem auto;
    grid-gap: 1rem;
    
}
.user-img img{
    width:2.5rem;
    border-radius: 50%;
}
.post-content input{

    background-color: #e3e3e3a6;
    border: none;
    outline: none;
    width:100%;
    padding: 0.5rem;
    border-radius: 0.5rem;

}
.post-icons{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 0.5rem;
    padding-top: 0.8rem;
    align-items: center;

}
.icons-content{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}
