.user-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 1.5rem;
    margin-top:1rem;
    overflow-x: clip;
}
.user-images{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.user-images img:nth-of-type(1){
    max-width: 100%;
    height: auto;
    object-fit: cover;
    /* border-radius: 1.5rem 1.5rem 0 0; */

}
.user-images img:nth-of-type(2){
    width: 6rem;
    border-radius: 50%;
    position: absolute;
    bottom: -3rem;
    
}
.user-details{
    margin-top: 3.5rem;
    text-align: center;
}
.follower-status{
    display:flex;
    border-top:2px solid black; 
    border-bottom:2px solid black; 
    justify-content: space-between;
    padding: 0.5rem;
    width: 70%;


}
.user-cart h2{
    color: var(--primary-color);
    text-align: center;
    padding: 1rem 0;
}
.followerss{
    text-align: center;
}
.user-details{
    margin-bottom: 1rem;
}