
.post-inner-content{
    margin: 0.5rem 0;
    background-color: var(--secondary-color);
    border-radius: 0.5rem;
    padding: 1rem;
}
.post-img{
    width:100%;
    height: 20rem;
    border-radius: 0.5rem;
    object-fit: cover;
    overflow-x: clip !important;

}

.post-share-icons img{
    padding-top:1rem;
}
.post-share-icons img:nth-of-type(2){
    margin:0 1rem;
}
