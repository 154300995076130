.followers{
    display: flex;
    flex-direction: column;
}
.followers-content{
    display: grid;
    grid-template-columns: 4rem auto 4rem;
    grid-gap: 0.5rem;
    box-shadow: 0px 2px 8px #00000069;
    margin-bottom: 1rem;
    padding: 10px;
    border-radius: 1rem;
    align-items: center;
    background-color: #fff;
}
.follower-img img{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
}
.followers-details h5{
    font-size: 1.1rem;
}
.followers-details p{
    font-size: 0.8rem;
}
.follow-btn{
    padding: 0.3rem;
    background-color: var(--primary-color);
    text-align: center;
    border-radius: 0.4rem;
}
.follower-heading{
    padding: 1rem 0;
}