/* .user-rightbar{
    display: flex;
    flex-direction: column;
} */

.user-icons{
    display: flex;
    justify-content: space-between;
}
.user-icons img{
    width:1.5rem;
    margin: 0.5rem 0;
}
.trending{
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin: 1rem 0px;
}
.trending h3{
    padding-bottom: 1rem;
}
.trending-content{
    padding-bottom: 0.8rem;
}