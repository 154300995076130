.rightsidebar{

    display: flex;
    flex-direction: column;
}
.searchbar{
    display: flex;
    gap: 0.5rem;
    align-items: center;
 
}

.search{
    display: flex;
    background-color: #e3e3e3a6;
    padding: 0.2rem 0.3rem;
    border-radius: 0.5rem;
}
.search input{

    background-color: #e3e3e3a6;
    padding: 0.2rem;
    border-radius: 0.2rem;
    border: none;
    outline: none;
    width: 13rem;
    max-width: 13rem;
    
    
}
