/* html{
  font-size: 62.5%;
} */
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: #e6e6e6a4 !important;
}

:root{
  --primary-color:orange;
  --secondary-color:white;
}

/* ////////////////////// Content Spacing /////////////////////// */

.content-spacing{
  max-width: 95%;
  padding:1rem 0;
  margin:auto;
}
a{
  text-decoration: none !important;

  color: var(--secondary-color) !important;
}
p{
  font-size: 16px;
  margin: 0px !important;
}
h1,h2,h3,h4,h5,h6{
  margin:0px !important;
}
h1,h2,h3{
  font-size: 1.1rem !important;
}
h4,h5,h6{
  font-size: 1rem !important;
}


/* ////////////////////// Buttons Style /////////////////////// */

.btn-primary{
  background-color: var(--primary-color) !important;
  font-size: 1rem !important;
  padding: 0.6rem !important;
  border-radius: 0.5rem;
  color: var(--secondary-color) !important;
  text-align: center;
}

@media (max-width:1080px) {

  .content-spacing {
    max-width: 90%;
}

  .main-inner-content {
    display: grid;
    
}
.post-user-conetent{
  order:1;
  grid-column: 1/4;
}
.rightsidebar{
  order:2;
  grid-column: 1/3;
}
.user-rightbar{
  order: 3;
}
}

@media (max-width:680px){

  .main-inner-content {
    display: flex !important;
    flex-direction: column;
    
}
.post-icons {
  grid-template-columns: repeat(3,1fr) !important;
}

}